@import "variables";

$background-color: $primary-color;
$foreground-color: #fff;

.Contact {
  background: $background-color;
  color: $foreground-color;

  ::selection {
    background: rgba($foreground-color, 0.25);
  }

  &-content {
    padding: $vertical-spacing 0 $vertical-spacing * 2;
    max-width: 520 / 16 * 1rem;
    margin: 0 auto;
  }

  &-title {
    text-align: center;
    text-transform: uppercase;
  }

  &-form {
    margin: 0 0.25rem;

    span {
      display: block;
      margin-bottom: 6px;
      font-weight: bold;
    }

    input,
    textarea,
    button {
      font-size: inherit;
      font-family: inherit;
      width: 100%;
      border-radius: 5px;
    }

    input {
      min-height: 50px;
      background: #fff;

      &[type="date"] {
        -webkit-appearance: none;
      }
    }

    input,
    textarea {
      border: 2px solid transparent;
      padding: 0.5rem;

      &:focus {
        outline: none;
        // border-color: $foreground-color;
      }
    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }

    button {
      background-color: $foreground-color;
      border: none;
      padding: 1rem;
      color: $primary-color;
      font-weight: bold;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.25rem;

      &:focus,
      &:hover,
      &:active {
        outline: none;
      }
    }
  }

  &-field {
    width: 100%;
    padding: 0 1rem 1rem;

    &--small {
      width: 33%;
      float: left;
    }
  }

  &-address {
    text-align: right;
    padding: $vertical-spacing 1rem 1rem;
  }

  &-icon {
    width: 80px;
    fill: #fff;
    margin-top: 1rem;
  }
}
