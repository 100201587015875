@import "variables";

html {
  background: $primary-color;
  box-sizing: border-box;
  font-size: 16px;

  @media (min-width: $xlarge-breakpoint) {
    font-size: 18px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #fff;
  margin: 0;
  padding: 0;
  font-family: Muli, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p,
h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 0;
}

#root {
  overflow: hidden;
  min-width: 320px;
}

::selection {
  background: rgba($primary-color, 0.25);
}

/* Utilities */

.u-section {
  max-width: $max-width;
  margin: 0 auto;
}

.u-subtitle {
  margin-bottom: $vertical-spacing;
  font-size: 1.75rem;
  font-weight: 300;
}

.u-fill {
  flex: 1;
}
