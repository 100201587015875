$nav-height: 60px;

$vertical-spacing: 3rem;
$horizontal-spacing: 1.5rem;
$gutter: 10/16 * 1rem;

$primary-color: #ec605f;
$primary-color-dark: #b12f2f;
$primary-color-darker: #652b2b;

$small-breakpoint: 375px;
$medium-breakpoint: 480px;
$large-breakpoint: 880px;
$xlarge-breakpoint: 960px;
$max-width: 1680px;

$shadow: 0 0 23px rgba(#000, 0.1);
