@import "variables";

.Nav {
  user-select: none;
  background: #fff;
  font-size: 16.5 / 16 * 1rem;
  box-shadow: 0 1px 8px rgba(#000, 0.075);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $nav-height;
  z-index: 999;

  li {
    padding: 8px;
  }

  &-logo {
    width: 110px;
    display: block;
    margin: 5px auto -8px;

    @media (min-width: $large-breakpoint) {
      margin: 10px -150px 10px 50px;
      float: left;
    }
  }

  &-item {
    position: relative;
    color: inherit;
    text-decoration: none;
    display: inline-block;
    padding: 10px 6px;
    text-align: center;
    border-radius: 6px;
  }

  &-item.is-active:after,
  &-cta:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background-image: url(./images/underline-black.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-cta:after {
    background-image: url(./images/underline-red.svg);
  }

  &-content {
    display: none;
    justify-content: center;

    @media (min-width: $large-breakpoint) {
      display: flex;
    }
  }

  &-social {
    position: absolute;
    right: 10px;
    top: 10px;

    &-link {
      padding: 5px;
    }
  }

  @media (max-width: $large-breakpoint - 1px) {
    &.is-open {
      background: #fff;
      height: 100%;
    }

    &.is-open &-content {
      display: flex;
      flex-direction: column;
      text-align: center;
      height: 100%;
      padding: 50px 0 170px;

      li {
        flex: 1;
      }
    }

    &-social {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      top: auto;
    }
  }

  &-button {
    position: absolute;
    top: 11px;
    right: 13px;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 10px;

    @media (min-width: $large-breakpoint) {
      display: none;
    }
  }
}
