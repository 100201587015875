@import "variables";

.Services {
  padding: $vertical-spacing 0 0;

  &-title {
    text-align: center;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter;
  }

  li {
    position: relative;

    background: #eee;
    background-size: cover;
    background-position: center;
    margin-right: $gutter;
    margin-bottom: $gutter;

    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - #{$gutter});

    @media (min-width: $medium-breakpoint) {
      flex-basis: calc(33.33% - #{$gutter});
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  &-name {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    text-align: center;
    font-size: 1.3rem;

    @media (min-width: $xlarge-breakpoint) {
      font-size: 1.75rem;
    }
  }

  li:hover &-name {
    background: rgba(#000, 0.5);
    opacity: 1;
    text-transform: uppercase;
    color: #fff;
    transition: all 200ms ease-in-out;
  }

  @media (max-width: $xlarge-breakpoint - 1px) {
    li &-name {
      background: rgba(#000, 0.5);
      opacity: 1;
      text-transform: uppercase;
      color: #fff;
      transition: all 200ms ease-in-out;
    }
  }
}
