@import "variables";

.Mascots {
  padding: $vertical-spacing 0 0;

  // To compensate the border the images have
  margin: 0 $gutter * 2;

  &-title {
    text-align: center;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter;
  }

  li {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: $gutter;

    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - #{$gutter});
    text-align: center;
    text-transform: uppercase;
    font-size: 0.9rem;

    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(9),
    &:nth-child(12),
    &:nth-child(15) {
      flex-basis: calc(100% - #{$gutter});

      &:after {
        padding-bottom: 0;
      }
    }

    @media (min-width: $medium-breakpoint) {
      flex-basis: calc(20% - #{$gutter});

      &:nth-child(2) {
        order: 1;
      }
      &:nth-child(5) {
        order: 2;
      }
      &:nth-child(8) {
        order: 3;
      }
      &:nth-child(11) {
        order: 4;
      }
      &:nth-child(14) {
        order: 5;
      }

      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(9),
      &:nth-child(12),
      &:nth-child(15) {
        flex-basis: calc(20% - #{$gutter});

        &:after {
          padding-bottom: 0;
        }
      }

      &:nth-child(3) {
        order: 6;
      }
      &:nth-child(6) {
        order: 7;
      }
      &:nth-child(9) {
        order: 8;
      }
      &:nth-child(12) {
        order: 9;
      }
      &:nth-child(15) {
        order: 10;
      }
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}
