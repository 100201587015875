@import "variables";

.Testimonial {
  &-content {
    padding: $vertical-spacing 0 $vertical-spacing * 2;
    max-width: 800 / 16 * 1rem;
    margin: 0 auto;
    text-align: center;
  }

  &-quote {
    font-size: 1.25rem;
  }
}
