@import "variables";

.Images {
  padding: $vertical-spacing 0 $vertical-spacing * 2;

  &-title {
    text-align: center;
    text-transform: uppercase;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter;
  }

  &-entry {
    position: relative;

    background: #eee;
    background-size: cover;
    background-position: center;
    margin-right: $gutter;
    margin-bottom: $gutter;
    color: transparent;
    text-transform: uppercase;
    overflow: hidden;

    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - #{$gutter});

    @media (min-width: $large-breakpoint) {
      flex-basis: calc(25% - #{$gutter});
    }

    &-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background-color: #fff;
      color: #000;
      opacity: 0;
      padding: 1rem;
      cursor: pointer;

      @media (min-width: $small-breakpoint) {
        padding: 1.5rem;
      }
    }

    &:hover &-content {
      opacity: 1;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  &-name {
    font-weight: bold;
    padding-bottom: 0.5rem;
    font-size: 1rem;

    @media (min-width: $small-breakpoint) {
      font-size: 1.15rem;
    }

    @media (min-width: $medium-breakpoint) {
      font-size: 1.25rem;
    }
  }

  &-description {
    font-size: 0.7rem;

    @media (min-width: $small-breakpoint) {
      font-size: 0.8rem;
    }

    @media (min-width: $medium-breakpoint) {
      font-size: 0.95rem;
    }
  }
}

.slick-dots li {
  width: 6px;
  height: 6px;
}

.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;

  &:before,
  &:before {
    font-size: 30px;
  }
}

.slick-prev {
  left: 20px;
  z-index: 1;
}

.slick-next {
  right: 20px;
  z-index: 1;
}
