$vertical-spacing: 2rem;

.Hero {
  position: relative;
  text-align: center;
  height: 225 / 400 * 100vw;
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 2rem;

  &-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  &-content {
    position: relative;
    color: transparent;
    height: 0;
    overflow: hidden;
  }
}
