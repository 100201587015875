@charset "UTF-8";
.Plans {
  padding: 3rem 0 0;
  font-size: 1.25rem; }
  .Plans-title {
    text-align: center;
    text-transform: uppercase; }
  .Plans-table {
    display: flex;
    text-align: center;
    flex-wrap: wrap; }
  .Plans-item {
    border-radius: 1rem;
    border: 10px solid #fff;
    padding: 1.5rem 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    display: flex;
    flex-direction: column;
    background: #f7f7f7;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%; }
    @media (min-width: 375px) {
      .Plans-item {
        flex-basis: calc(50% - 0.625rem);
        margin-right: 0.3125rem;
        margin-left: 0.3125rem; } }
    @media (min-width: 880px) {
      .Plans-item {
        flex: 1; } }
    .Plans-item ul {
      flex: 1;
      display: flex;
      flex-direction: column; }
    .Plans-item li {
      padding: 4px;
      line-height: 1.5rem; }
      .Plans-item li:last-child {
        font-weight: bold; }
    .Plans-item h3 {
      text-transform: uppercase;
      font-weight: 300; }
    .Plans-item-footer {
      position: relative; }
      .Plans-item-footer:after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        background-image: url(./images/underline-black.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; }
    .Plans-item-feature--small {
      font-size: 80%;
      margin-top: 16px;
      margin-bottom: 8px; }
    .Plans-item-feature:after {
      content: "✔";
      font-size: 80%;
      margin-left: 8px; }
    .Plans-item--1 .Plans-item-feature:after {
      color: #577cbd; }
    .Plans-item--2 .Plans-item-feature:after {
      color: #ec605f; }
    .Plans-item--3 .Plans-item-feature:after {
      color: #f2c300; }
    .Plans-item--4 .Plans-item-feature:after {
      color: #28afa6; }
  .Plans-image {
    margin: 0 50px;
    flex: none; }
    .Plans-image img {
      max-width: 100%; }
